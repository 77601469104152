import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import {
    DefaultButton,
    Dialog, DialogFooter, DialogType, PrimaryButton,
} from '@fluentui/react'
import Candidacy from 'requests/objects/candidacy'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import NotImplementedError from 'requests/errors/notImplementedError'
import CandidaciesHandler from 'requests/handlers/candidaciesHandler'
import Status from 'types/status'
import HuntsHandler from 'requests/handlers/huntsHandler'
import FilteredVirtualComboboxAjax from 'components/inputs/filteredVirtualComboboxAjax'
import SearchFiltersHandler from 'requests/handlers/searchFiltersHandler'
import Hunt from 'requests/objects/hunt'

const searchFiltersHandler = new SearchFiltersHandler()

/**
 * BoardTransferModal
 * @param {object} props Props
 * @param {Candidacy | Hunt} props.item item
 * @param {CandidaciesHandler | HuntsHandler} props.handler handler
 * @param {boolean} props.isVisible isVisible
 * @param {(isVisible: boolean) => void} props.setIsVisible setIsVisible
 * @param {(item: Candidacy | Hunt) => void} props.onUpdate onUpdate
 * @returns {JSX.Element} Returns
 */
export default function BoardTransferModal({
    item: itemProps, handler, isVisible, setIsVisible, onUpdate,
}) {
    const [item, setItem] = useState(itemProps || new Candidacy())
    const [status, setStatus] = useState(Status.IDLE)
    const [missionId, setMissionId] = useState(null)

    /** @type {React.MutableRefObject<RequestApi<Candidacy | Hunt>>} */
    const handlerUpdateBoardItem = useRef(null)

    const update = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            handlerUpdateBoardItem.current = handler.patchTransfer(
                /** @type {Candidacy} */(item).candidacyId || /** @type {Hunt} */(item).huntId,
                missionId,
            )
            const newItem = await handlerUpdateBoardItem.current.fetch()
            onUpdate(newItem)
            setStatus(Status.RESOLVED)
            setIsVisible(false)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                    break
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
            }
        }
    }, [handler, item, missionId, onUpdate, setIsVisible])

    // Update local item on props change
    useEffect(() => {
        if (isVisible)
            setItem(itemProps || new Candidacy())
    }, [isVisible]) // eslint-disable-line react-hooks/exhaustive-deps

    // Cancel request on dismount
    useEffect(() => () => {
        handlerUpdateBoardItem?.current?.cancel()
    }, [])

    return (
        <Dialog
            hidden={!isVisible}
            onDismiss={() => setIsVisible(false)}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Transférer la candidature',
                // eslint-disable-next-line max-len
                subText: "Voulez-vous transférer la candidature vers une autre mission ? Seul le dernier événement dans la candidature sera repris vers la nouvelle mission, les autres seront conservés sur la mission originale. L'actuelle candidature sera rejetée.",
            }}
            modalProps={{
                isBlocking: true,
            }}
            maxWidth="555px"
            minWidth="555px"
        >
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                    update()
                }}
            >
                <FilteredVirtualComboboxAjax
                    label="Mission"
                    minChar={1}
                    defaultValue=""
                    readOnly={false}
                    selectedKey={missionId}
                    onChange={(_ev, option) => setMissionId(option.key)}
                    functionName="searchMissions"
                    handler={searchFiltersHandler}
                    required
                />
                <DialogFooter>
                    <DefaultButton
                        onClick={() => setIsVisible(false)}
                        text="Annuler"
                        disabled={status === Status.PENDING}
                    />
                    <PrimaryButton
                        type="submit"
                        text="Valider"
                        disabled={status === Status.PENDING}
                    />
                </DialogFooter>
            </form>
        </Dialog>
    )
}
