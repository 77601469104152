import React, {
    useCallback,
    useEffect, useMemo, useRef, useState,
} from 'react'
import {
    DefaultButton, Icon, Panel, PanelType, PrimaryButton, Text, TextField,
} from '@fluentui/react'
import Candidacy from 'requests/objects/candidacy'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import CandidaciesHandler from 'requests/handlers/candidaciesHandler'
import Status from 'types/status'
import styles from 'styles/components/pages/missions/pivotCandidacy/boardInterviewPanel.module.scss'
import classNames from 'classnames'
import HuntsHandler from 'requests/handlers/huntsHandler'
import Hunt from 'requests/objects/hunt'
import Loader from 'components/visuals/loader'
import Interview, { ErrorInterview } from 'requests/objects/interview'
import Divider from 'components/visuals/divider'
import store from 'redux/store'
import { setMessageBar } from 'redux/slices/common'

/**
 * BoardInterviewPanel
 * @param {object} props Props
 * @param {Candidacy | Hunt} props.item item
 * @param {CandidaciesHandler | HuntsHandler} props.handler handler
 * @param {boolean} props.isVisible isVisible
 * @param {(isVisible: boolean) => void} props.setIsVisible setIsVisible
 * @param {(item: Candidacy | Hunt) => void} props.onUpdate onUpdate
 * @returns {JSX.Element} Returns
 */
export default function BoardInterviewPanel({
    isVisible, setIsVisible, item: boardItemProps, handler, onUpdate,
}) {
    const [item, setItem] = useState(new Interview())
    const [errorField, setErrorField] = useState(new ErrorInterview())
    const [cvStatus, setCvStatus] = useState(Status.IDLE)
    /** @type {[Blob, React.Dispatch<React.SetStateAction<Blob>>]} */
    const [cvBlob, setCvBlob] = useState(null)
    const [status, setStatus] = useState(Status.IDLE)

    const cvBlobUrl = useMemo(() => (cvBlob ? window.URL.createObjectURL(cvBlob) : null), [cvBlob])

    /** @type {React.MutableRefObject<NodeJS.Timer>} */
    const timerRef = useRef(null)

    /** @type {React.MutableRefObject<RequestApi<Blob>>} */
    const handlerGetFile = useRef(null)
    /** @type {React.MutableRefObject<RequestApi<Interview>>} */
    const handlerUpsertInterview = useRef(null)
    /** @type {React.MutableRefObject<RequestApi<Interview>>} */
    const handlerUpsertSyncInterview = useRef(null)

    const upsert = useCallback(
        /**
         * @param {boolean} isClassicSave Is classic save, aka not a syncing update
         */
        async (isClassicSave = true) => {
            try {
                if (isClassicSave) {
                    handlerUpsertSyncInterview?.current?.cancel()
                    setStatus(Status.PENDING)
                    handlerUpsertInterview.current = handler.upsertInterview(
                        item,
                        /** @type {Candidacy} */(boardItemProps).candidacyId || /** @type {Hunt} */(boardItemProps).huntId,
                        item.interviewId,
                        true,
                    )
                    const interview = await handlerUpsertInterview.current.fetch()
                    setStatus(Status.RESOLVED)
                    setIsVisible(false)
                    onUpdate({ ...boardItemProps, interview })
                } else {
                    handlerUpsertSyncInterview.current = handler.upsertInterview(
                        item,
                        /** @type {Candidacy} */(boardItemProps).candidacyId || /** @type {Hunt} */(boardItemProps).huntId,
                        item.interviewId,
                    )
                    const interview = await handlerUpsertSyncInterview.current.fetch()
                    setItem(prev => ({ ...prev, interview, interviewId: interview.interviewId }))
                }
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case NotImplementedError:
                        break
                    case InvalidEntityError:
                        store.dispatch(setMessageBar({ isDisplayed: false }))
                        setErrorField(/** @type {InvalidEntityError<ErrorInterview>} */(error).errorField)
                        // eslint-disable-next-line no-console
                        console.error(error)
                        setStatus(Status.REJECTED)
                        break
                    default:
                        store.dispatch(setMessageBar({ isDisplayed: false }))
                        setStatus(Status.REJECTED)
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        }, [boardItemProps, handler, item, onUpdate, setIsVisible],
    )

    // Download cv
    useEffect(() => {
        if (isVisible)
            if (boardItemProps.fileCv?.fileCvId)
                (async () => {
                    try {
                        setCvStatus(Status.PENDING)
                        handlerGetFile.current = handler.getFileCv(
                            /** @type {Candidacy} */(boardItemProps).candidacyId || /** @type {Hunt} */(boardItemProps).huntId,
                            boardItemProps.fileCv?.fileCvId,
                            'application/pdf',
                        )
                        const blob = await handlerGetFile.current.fetch()
                        setCvBlob(blob)
                        setCvStatus(Status.RESOLVED)
                    } catch (error) {
                        switch (error?.constructor) {
                            case CancelRequestError:
                            case UnauthorizedError:
                            case InvalidEntityError: break
                            case NotImplementedError:
                            default:
                                setCvStatus(Status.REJECTED)
                                store.dispatch(setMessageBar({ isDisplayed: false }))
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                        }
                    }
                })()
            else
                setCvStatus(Status.RESOLVED)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible])

    // Handle auto save
    useEffect(() => {
        if (isVisible) {
            clearInterval(timerRef.current)
            timerRef.current = setInterval(() => upsert(false), 5000)
        } else {
            clearInterval(timerRef.current)
        }

        return () => {
            clearInterval(timerRef.current)
        }
    }, [isVisible, upsert])

    // Update local item on props change
    useEffect(() => {
        if (boardItemProps) {
            setItem(boardItemProps?.interview || new Interview())
            setErrorField(new ErrorInterview())
        }
    }, [boardItemProps])

    // Cancel request on dismount
    useEffect(() => () => {
        handlerUpsertInterview?.current?.cancel()
        handlerGetFile?.current?.cancel()
        handlerUpsertSyncInterview?.current?.cancel()
    }, [])

    return (
        <Panel
            className={classNames(styles['board-interview-panel'])}
            headerText="Dossier candidat"
            isOpen={isVisible}
            onDismiss={() => {
                upsert(false)
                setIsVisible(false)
            }}
            closeButtonAriaLabel="Close"
            type={PanelType.custom}
            customWidth="98vw"
            styles={{
                scrollableContent: {
                    flexGrow: 1,
                    display: 'inherit',
                    flexDirection: 'inherit',
                },
                content: {
                    height: '100%',
                },
            }}
        >
            <div className={classNames(styles['board-interview-panel-container'])}>
                <div className={classNames(styles['board-interview-panel-container-left'])}>
                    {cvStatus === Status.PENDING
                        && <Loader />}
                    {cvStatus === Status.RESOLVED && cvBlob && (
                        <iframe
                            className={classNames(styles['board-interview-panel-container-left-cv'])}
                            title="cv"
                            src={cvBlobUrl}
                        />
                    )}
                    {cvStatus === Status.RESOLVED && !cvBlob
                        && <Text>Aucun CV n'a été trouvé pour cette candidature.</Text>}
                    {cvStatus === Status.REJECTED
                        && <Text>Une erreur est survenu lors de la récupération du CV.</Text>}
                </div>
                <form
                    className={classNames(styles['board-interview-panel-container-right'])}
                    onSubmit={ev => {
                        ev.preventDefault()
                        upsert()
                    }}
                >
                    <div className={classNames(styles['board-interview-panel-container-right-contents'])}>
                        <Text
                            variant="large"
                            block
                        >
                            <Icon iconName="PageData" />
                            {' '}
                            Données
                        </Text>
                        <Divider />
                        <Text
                            className={classNames(styles['board-interview-panel-container-right-contents-intro'])}
                            as="p"
                        >
                            Les champs sont enregistrés automatiquement toutes les 5 secondes.
                        </Text>
                        <TextField
                            label="Lieu de résidence / mobilité"
                            value={item.location}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prev => ({ ...prev, location: newVal }))}
                            errorMessage={errorField.location}
                            // multiline
                            autoAdjustHeight
                        />
                        <TextField
                            label="Formation"
                            value={item.formation}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prev => ({ ...prev, formation: newVal }))}
                            errorMessage={errorField.formation}
                            // multiline
                            autoAdjustHeight
                            required
                        />
                        <TextField
                            label="Rémunération actuelle (RA)"
                            value={item.currentSalary}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prev => ({ ...prev, currentSalary: newVal }))}
                            errorMessage={errorField.currentSalary}
                            required
                            multiline
                            autoAdjustHeight
                        />
                        <TextField
                            label="Rémunération souhaitée (RS)"
                            value={item.desiredSalary}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prev => ({ ...prev, desiredSalary: newVal }))}
                            errorMessage={errorField.desiredSalary}
                            multiline
                            autoAdjustHeight
                        />
                        <TextField
                            label="Délais de disponibilité"
                            value={item.availability}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prev => ({ ...prev, availability: newVal }))}
                            errorMessage={errorField.availability}
                            // multiline
                            // autoAdjustHeight
                            maxLength={100}
                        />
                        {/* <Divider style={{ marginBottom: 0 }} />
                        <Label>Aptitudes (Personnalité / Savoir être)</Label> */}
                        <TextField
                            label="Aptitudes (Personnalité / Savoir être) - Points d'appui"
                            value={item.aptitudePros}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prev => ({ ...prev, aptitudePros: newVal }))}
                            errorMessage={errorField.aptitudePros}
                            multiline
                            autoAdjustHeight
                        />
                        <TextField
                            label="Aptitudes (Personnalité / Savoir être) - Points de vigilance"
                            value={item.aptitudeCons}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prev => ({ ...prev, aptitudeCons: newVal }))}
                            errorMessage={errorField.aptitudeCons}
                            multiline
                            autoAdjustHeight
                        />
                        {/* <Divider style={{ marginBottom: 0 }} />
                        <Label>Compétences (Adéquation par rapport au poste)</Label> */}
                        <TextField
                            label="Compétences (Adéquation par rapport au poste) - Points d'appui"
                            value={item.professionnalSkillPros}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prev => ({ ...prev, professionnalSkillPros: newVal }))}
                            errorMessage={errorField.professionnalSkillPros}
                            multiline
                            autoAdjustHeight
                        />
                        <TextField
                            label="Compétences (Adéquation par rapport au poste)  - Points de vigilance"
                            value={item.professionnalSkillCons}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prev => ({ ...prev, professionnalSkillCons: newVal }))}
                            errorMessage={errorField.professionnalSkillCons}
                            multiline
                            autoAdjustHeight
                        />
                        <TextField
                            label="Motivations pour la société / pour le poste"
                            value={item.motivation}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prev => ({ ...prev, motivation: newVal }))}
                            errorMessage={errorField.motivation}
                            multiline
                            autoAdjustHeight
                            required
                        />
                        {/* <TextField
                            label="Commentaires (pistes…)"
                            value={item.track}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prev => ({ ...prev, track: newVal }))}
                            errorMessage={errorField.track}
                            multiline
                            autoAdjustHeight
                        /> */}
                    </div>
                    <div className={classNames(styles['board-interview-panel-container-right-buttons'])}>
                        <DefaultButton
                            onClick={() => {
                                upsert(false)
                                setIsVisible(false)
                            }}
                            text="Fermer"
                            disabled={status === Status.PENDING}
                        />
                        <PrimaryButton
                            text="Valider"
                            type="submit"
                            disabled={status === Status.PENDING}
                        />
                    </div>
                </form>
            </div>
        </Panel>
    )
}
